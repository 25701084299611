p {
    margin: 0
}
.message {
  max-width: 90%;
  border: 1px solid #DDD;
  border-radius: 10px;
  padding: 10px;
  margin: 5px 0;
  position: relative;
}

.userMessage {
    margin-left: auto;
    background-color: #D6EAF8;
}

.personaMessage {
    margin-right: auto;
    background-color: #DCF8C6;

    color: #192239; /* dark dark blue (for text) */
}

.editMessage {
    min-width: 400px;
}

.editButton {
    display: none;
    position: absolute;
    bottom: 5px;
    right: 5px;
    border: 0;
    color: white;
    text-align: center;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 10px;
    padding: 5px 8px;
}

.message:hover .editButton {
    display: block;
}
