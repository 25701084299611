.chatLogContainer {
    float: left;
    overflow: scroll;
    margin: 0 auto;
    width: 100%;
    display:flex;
    flex-direction: column;
    height: 100%;
}

