.appContainer {
    #background-image: url('../public/images/tavern.png');
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.chatContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 5%;
    float: left;
}

.chatInputContainer {
    margin-top: auto;
    width: 100%;
    margin: 0 auto;
    z-index: 2;
}

form textarea {
  width: calc(100% - 20px); /* need to subtract 2xpadding */
  border: none;
  border-radius: 10px;
  background-color: #F0F0F0;
  font-size: 16px;
  padding: 10px;
  resize: none;
}

button {
    background-color: #808080;
}

button.hidden {
  visibility: hidden;
}


.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

textarea:disabled {
  background-color: darkgray;
  color: #918e8e;
  cursor: not-allowed;
}

.menu {
    width: 200px;
    height: 100%;
    position: relative;

}
