.personaContainer {
    float: left;
    width: 35%;
    min-width: 300px;
    height: 100%;
    margin-left: 5%;
    position: relative;
}
.personaPicture {
    max-width:100%;
    max-height:100%;
    position: absolute;
    bottom: 0;
}
